import React from 'react';
import PropTypes from 'prop-types';

const Container = ({
    children,
    className,
    narrow,
}) => {
    return (
        <div className={`o-wrapper ${narrow && 'o-wrapper--narrow'} ${className}`}>
            { children }
        </div>
    );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;