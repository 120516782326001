import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { IconContext } from "react-icons";

import 'assets/stylesheets/application.scss';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Container from './Container';

const Main = ({
	children
}) => {
	return (
		<main className="o-container__main">{children}</main>
	)
}

const Layout = ({ children, pageName }) => {

	let className = '';

	if ( pageName ) {
		className = `${className} page-${pageName}`;
	}

	return (
		<>
			<IconContext.Provider value={{ className: "o-icon" }}>
				<Helmet
					bodyAttributes={{ class: className }}
					titleTemplate="YouPos - %s"
				>
					<html lang="en" />
				</Helmet>
				<Container>
					<Header />
					<Main children={children} />	
					<Footer />
				</Container>
			</IconContext.Provider>
		</>
	);

};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;