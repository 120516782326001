import React from 'react';
// import { Link } from 'gatsby';

import Wrapper from 'components/Wrapper';

const Footer = () => {
    return (
        <footer class="c-site-footer">
            <Wrapper>
                {/* <nav className="c-site-footer__nav">
                    <ul className="c-site-footer__nav-list">
                        <li className="c-site-footer__nav-item">
                            <Link className="c-site-footer__nav-link" to="/">Home</Link>
                        </li>
                        <li className="c-site-footer__nav-item">
                            <Link className="c-site-footer__nav-link" to="/page-2/">Page 2</Link>
                        </li>
                        <li className="c-site-footer__nav-item">
                            <Link className="c-site-footer__nav-link" to="/test-page/">Test Page</Link>
                        </li>
                    </ul>
                </nav> */}
                <p>Email us at <a href="hello@youpos.co.uk">hello@youpos.co.uk</a></p>
                <p className="u-mb-0">&copy; {new Date().getFullYear()}, YouPos</p>
            </Wrapper>
        </footer>
    );
};

export default Footer;