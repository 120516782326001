import React from 'react';

import Wrapper from 'components/Wrapper';

import img_logo from 'assets/images/youpos-logo.png';

const Header = () => {
    return (
        <header className="c-site-header">
            <Wrapper className="c-site-header__wrapper">
                <a href="/" className="c-site-header__home-link"><img src={img_logo} alt="YouPos Logo" class="c-site-header__logo" />
                    <div className="u-hide">Home</div>
                </a>
                <nav className="c-site-header__nav">
                    <ul className="c-site-header__nav-list">
                        <li className="c-site-header__nav-item">
                            <a href="#how-it-works" className="c-site-header__nav-link">How It Works</a>
                        </li>
                        <li className="c-site-header__nav-item">
                            <a href="#usps" className="c-site-header__nav-link">Why YouPos?</a>
                        </li>
                        <li className="c-site-header__nav-item">
                            <a href="#faqs" className="c-site-header__nav-link">FAQs</a>
                        </li>
                        <li className="c-site-header__nav-item c-site-header__nav-item--cta">
                            <a href="#register-interest" className="c-site-header__cta c-button">
                                <span className="c-site-header__desktop-cta-text">Find Out More</span>
                                <span className="c-site-header__mobile-cta-text" aria-hidden="true">Sign Up</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Wrapper>
        </header>
    );
};

export default Header;